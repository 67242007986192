<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Autogestor</v-card-title>
      <CButton
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        >+ Nuevo</CButton
      >

      <v-card-text>
        <div class="text-center" v-if="loadingCategorias">
          <div class="spinner-border" role="status"></div>
        </div>
        <filters
          v-if="!loadingCategorias"
          :campos="filterFields"
          :itemsKeys="{
            text: 'name',
            value: 'id',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters>
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        <CDataTable
          v-if="!loading"
          hover
          striped
          :items="items"
          :fields="fields"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
          <template #activo="data">
            <td>
              <CBadge :color="data.item.activo?'success':'secondary'">
                {{ data.item.activo==1?'Habilitado':'Inhabilitado' }}
              </CBadge>
            </td>
          </template>
          <template #acciones="data">
            <td>
              <CLink
                href="#"
                @click="editar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Editar'"
              >
                <CIcon name="cil-pencil" />
              </CLink>
              <CLink
                href="#"
                @click="copiar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Copiar'"
              >
                <!-- <CIcon name="cil-clone" /> -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="c-icon"><path fill="var(--ci-primary-color, currentColor)" d="M472,16H160a24.027,24.027,0,0,0-24,24V352a24.027,24.027,0,0,0,24,24H472a24.027,24.027,0,0,0,24-24V40A24.027,24.027,0,0,0,472,16Zm-8,328H168V48H464Z" class="ci-primary"></path><path fill="var(--ci-primary-color, currentColor)" d="M344,464H48V168h56V136H40a24.027,24.027,0,0,0-24,24V472a24.027,24.027,0,0,0,24,24H352a24.027,24.027,0,0,0,24-24V408H344Z" class="ci-primary"></path></svg>
              </CLink>
              <CLink
                href="#"
                @click="roles(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Roles de acceso'"
              >
                <CIcon name="cil-people" />
              </CLink>
              <CLink
                href="#"
                @click="preEliminar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Eliminar'"
              >
                x
              </CLink>
            </td>
          </template>
          
        </CDataTable>
      </v-card-text>
    </v-card>
    <br><br>
    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea eliminar el registro?
        </v-card-title>

        <v-card-text>
          {{deleteItem?deleteItem.id:''}} - {{deleteItem?deleteItem.nombre:''}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>

          <v-btn color="gray darken-1" text @click="eliminar"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Filters from "@/components/filters";
import definicionConsultaService from "@/services/definicionConsulta.service";
import ListaService from "@/services/lista.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Layout",
  data() {
    return {
      items: [],
      fields: [
        { key: "id", label: "Código" },
        { key: "nombre", label: "Nombre", _classes: "font-weight-bold" },
        { key: "categoria", label: "Categoría" },
        { key: "activo", label: "Estado" },
        { key: "acciones", label: "Acciones" },
      ],
      filterFields: [
        { value: "id", label: "Código" },
        { value: "nombre", label: "Nombre" },
        {
          value: "categoria_id",
          label: "Categoría",
          options: [],
        },
        {
          value: "activo",
          label: "Estado",
          options: [
            { id: 1, name: "Habilitado" },
            { id: 0, name: "Inhabilitado" },
          ],
        },
      ],
      activePage: 1,
      loading: false,
      message: "",
      dialog: false,
      loadingCategorias: false,
      deleteItem: undefined,
    };
  },
  components: {
    Filters,
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Autogestor - Definición de consulta"))
      this.$router.push("/dashboard");
    this.fetchItems("");
    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      this.fetchItems(cadenaBusqueda);
    });
    this.fetchCategoriasConsultas('')
  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      definicionConsultaService.getItems(cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          // this.items = this.items.map(function f(x) {
          //   return { ...x, layout_nombre: x.layout.nombre };
          // });
          console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    fetchCategoriasConsultas(cadenaBusqueda) {
      this.loadingCategorias = true;
      ListaService.getCategoriasConsultas().then(
        (response) => {
          this.filterFields[2].options = response.data.data;
          this.filterFields[2].options = this.filterFields[2].options.map(
            function f(x) {
              return { ...x, name: x.valor_texto };
            }
          );
          this.loadingCategorias = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCategorias = false;
        }
      );
    },
    crear() {
      localStorage.removeItem("datos_autogestor_preconsulta");
      this.$router.push({ path: `gestor-consultas/editar/` });
    },
    editar(item) {
      localStorage.setItem("datos_autogestor_preconsulta", JSON.stringify(item));
      this.$router.push({ path: `gestor-consultas/editar/` });
    },
    copiar(item) {
      item.nombre = `${item.nombre} - Copia`
      item.id = undefined
      item.categoria = undefined
      item.layout = undefined
      localStorage.setItem("datos_autogestor_preconsulta", JSON.stringify(item));
      this.$router.push({ path: `gestor-consultas/editar/` });
    },
    roles(item) {
      localStorage.setItem("datos_autogestor_preconsulta", JSON.stringify(item));
      this.$router.push({ path: `gestor-consultas/roles-acceso/` });
    },
    preEliminar(item) {
      this.deleteItem = item;
      this.dialog = true;
    },
    eliminar() {
      this.loading = true;
      this.dialog = false;
      definicionConsultaService.deleteItem(this.deleteItem.id).then(
          (response) => {
            console.log(response.data);
            this.fetchItems("");
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
